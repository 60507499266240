import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../../components/seo';
import Navigation from '../../../components/navigation';
import { TileSection, Tile } from '../../../components/tile-section';
import Discover from '../../../components/discover';
import ServiceAndMaintenance from '../../../components/service-and-maintenance';

import { Languages } from '../../../tools/languages';

const CategoryTiles = ({ category, solutions }) => {
  return (
    <TileSection>
      {solutions.map((solution) => {
        const { title, slug, image } = solution;
        return (
          <Tile
            key={title}
            img={image ? image.childImageSharp.fluid : 'https://via.placeholder.com/640?text=Bavak'}
            to={slug}
            title={category}
            name={title}
          ></Tile>
        );
      })}
    </TileSection>
  );
};

const DutchSectorCategoryDetailPage = ({ location, data, language = Languages.NL }) => {
  const category = data.categories;
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title={category.title} lang={language} />
      <Navigation />
      <main>
        <h1>{category.title}</h1>
        <CategoryTiles category={category.title} solutions={category.solutions} />
        <ServiceAndMaintenance language={language} />
        <Discover dark={true} language={language} />
      </main>
    </>
  );
};

export const query = graphql`
  query DutchSolutionCategory($slug: String!) {
    categories: strapiSolutionCategories(slug: { eq: $slug }) {
      slug
      title: title_nl
      solutions {
        slug
        title: title_nl
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default DutchSectorCategoryDetailPage;
